<template>
  <div>
    <div class="bg-light p-3 mb-3 rounded">
      <h2>Entry</h2>
      <div>
        <small>Please enter months separately</small>
      </div>
      <form @submit.prevent="submitProjectReturn">
        <div class="row mb-3">
          <div class="col">
            <label for>Month</label>
            <input required v-model="projectReturnsForm.remarks" type="text" class="form-control" />
          </div>
          <div class="col">
            <label for>Amount</label>
            <input
              required
              type="number"
              step="any"
              v-model="projectReturnsForm.amount"
              class="form-control"
            />
          </div>
          <div class="col">
            <button type="submit" :disabled="loading" class="btn btn-primary mt-4">Submit</button>
          </div>
        </div>
      </form>
    </div>

    <h2>Returns</h2>

    <table class="table table-bordered">
      <tr>
        <th>Date</th>
        <th>Remarks</th>
        <th>Amount</th>
        <th>Login</th>
      </tr>
      <tr v-for="(val) in returns" :key="val.id">
        <td>{{ val.created_at }}</td>
        <td>{{ val.remarks }}</td>
        <td>{{ val.amount }}</td>
        <td>{{ val.login }}</td>
      </tr>
      <tr>
        <td colspan="2"></td>
        <td>{{ sum | currency }}</td>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    project: Object
  },
  data() {
    return {
      loading: false,
      returns: [],
      projectReturnsForm: {
        project_uuid: "",
        amount: 0,
        remarks: ""
      }
    };
  },
  mounted() {
    this.init();
    this.projectReturnsForm.project_uuid = this.project.uuid;
  },
  computed: {
    sum() {
      let sum = 0;
      this.returns.forEach(el => {
        sum += el.amount;
      });
      return sum;
    }
  },
  methods: {
    init() {
      api.bonds.getProjectReturn(this.project.uuid).then(res => {
        this.returns = res.data.data;
      });
    },
    submitProjectReturn(e) {
      e.preventDefault();
      this.loading = true;
      api.bonds
        .postProjectReturn(this.project.uuid, this.projectReturnsForm)
        .then(res => {
          this.init();
          this.projectReturnsForm.amount = 0;
          this.projectReturnsForm.remarks = "";
          this.loading = false;
        });
    }
  }
};
</script>