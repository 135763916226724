<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <button
        @click="modals.addProjectModal = true; mode = 1; tab = 1; resetForm()"
        class="btn btn-primary"
      >Add project</button>
      <button @click="init()" class="btn btn-primary">Refresh</button>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th class="tcol">Name</th>
                  <th>REF</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Months</th>
                  <!-- <th>Months</th> -->
                  <th>SP</th>
                  <th>CP</th>
                  <th>Monthly</th>
                  <th>Rcvd</th>
                  <th>Rcvbl</th>
                  <th>Outstanding</th>
                  <th>Assigned</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(val,i) in projects"
                  :key="val.id"
                  :class="{ 'table-danger': val.days_to_end < 50 }"
                >
                  <td>{{ i+1 }}</td>
                  <td>
                    <router-link :to="'projects/' + val.uuid">{{ val.name }}</router-link>
                    <span
                      v-if="$store.getters['auth/user'].is_super"
                      class="float-right badge badge-primary pointer"
                      @click="editProject($event, val)"
                    >Edit</span>
                    <span v-if="val.hasdue" class="float-right badge badge-danger mr-2">Due</span>
                  </td>
                  <td>{{ val.project_code }}</td>
                  <td>{{ val.sdt }}</td>
                  <td>{{ val.edt }}</td>
                  <td>{{ val.months_to_end }} / {{ val.months }}</td>
                  <!-- <td>{{ val.months }}</td> -->
                  <td>{{ val.sales_price | currency }}</td>
                  <td>{{ val.invested_amount | currency }}</td>
                  <td>{{ val.monthly_return | currency }}</td>
                  <td>{{ val.rcvd | currency }}</td>
                  <td>{{ parseFloat(val.sales_price) - parseFloat(val.rcvd) | currency }}</td>
                  <td>{{ parseFloat(val.rcvd) - parseFloat(val.cumulative) | currency }}</td>
                  <td>{{ getProjectInvestTotal(val.investments) | currency }}</td>
                  <td>{{ parseFloat(val.monthly_return) - parseFloat(getProjectInvestTotal(val.investments)) }}</td>
                </tr>
              </tbody>
              <tr>
                <th colspan="6">Total</th>
                <th>{{ totalSalePrice | currency }}</th>
                <th>{{ investTotal | currency }}</th>
                <th>{{ monthlyTotal | currency }}</th>
                <th>{{ grandTotalRcvd | currency }}</th>
                <th>{{ grandTotalRcvbl | currency }}</th>
                <th>{{ grandTotalOutstanding | currency }}</th>
                <th>{{ grandTotalBondInvestments | currency }}</th>
                <th>{{ parseFloat(monthlyTotal) - parseFloat(grandTotalBondInvestments) | currency }}</th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="modals.addProjectModal" modal-classes="modal-dialog-centered modal-lg">
      <!-- <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Project</h5>
      </template>-->
      <div class="p-3">
        <div class="content">
          <h3 class="text-uppercase">Edit project</h3>
          <!-- <div v-if="mode == 2" class="mb-4">
            <button
              class="btn btn-secondary"
              :class="{ 'btn-success': tab == 1 }"
              @click="tab = 1"
            >Edit project</button>
            <button
              class="btn btn-secondary"
              :class="{ 'btn-success': tab == 2 }"
              @click="tab = 2"
            >Returns</button>
          </div>-->

          <!-- PROJECT DETAILS -->
          <div v-if="tab == 1">
            <form @submit.prevent="submit">
              <div class="row mb-3">
                <div class="col">
                  <label for>Name</label>
                  <input v-model="form.name" type="text" class="form-control" />
                </div>
                <div class="col">
                  <label for>Monthly return</label>
                  <input
                    type="number"
                    step="any"
                    v-model="form.monthly_return"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <label for>Start date</label>
                  <base-input addon-left-icon="ni ni-calendar-grid-58">
                    <flat-picker
                      slot-scope="{focus, blur}"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{allowInput: true}"
                      class="form-control datepicker"
                      v-model="form.start_date"
                    ></flat-picker>
                  </base-input>
                </div>
                <div class="col">
                  <label for>End date</label>
                  <base-input addon-left-icon="ni ni-calendar-grid-58">
                    <flat-picker
                      slot-scope="{focus, blur}"
                      @on-open="focus"
                      @on-close="blur"
                      :config="{allowInput: true}"
                      class="form-control datepicker"
                      v-model="form.end_date"
                    ></flat-picker>
                  </base-input>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for>CP</label>
                  <input
                    type="number"
                    step="any"
                    v-model="form.invested_amount"
                    class="form-control"
                  />
                </div>
                <div class="col">
                  <label for>SP</label>
                  <input type="number" step="any" v-model="form.sales_price" class="form-control" />
                </div>
                <div class="col">
                  <label for>Months</label>
                  <input type="number" step="any" v-model="form.months" class="form-control" />
                </div>
                <div class="col">
                  <label for>Locked</label>
                  <div>
                    <input type="checkbox" v-model="form.locked" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label for>Project code</label>
                  <div>
                    <input type="text" required class="form-control" v-model="form.project_code" />
                  </div>
                </div>
                <div class="col" v-if="mode == 2">
                  <label for>Send due payment alert</label>
                  <div>
                    <input type="checkbox" v-model="form.send_alert" />
                  </div>
                </div>
              </div>
              <div class="mt-4" v-if="mode == 2">
                <h4 class="text-uppercase">
                  Owner
                  <span v-if="form.owner_id == null">
                    <span
                      v-if="ownerSelect"
                      @click="ownerSelect = false; form.owner_id_select = null"
                      class="badge badge-primary pointer float-right"
                    >Add new owner</span>
                    <span
                      v-else
                      @click="ownerSelect = true"
                      class="badge badge-primary pointer float-right"
                    >Select owner</span>
                  </span>
                </h4>
                <div v-if="form.owner_id == null">
                  <div v-if="ownerSelect">
                    <div>
                      <select
                        v-model="form.owner_id_select"
                        class="form-control"
                        v-if="owners.length > 0"
                      >
                        <option
                          :value="owner.id"
                          v-for="owner in owners"
                          :key="owner.id"
                        >{{ owner.name }} ({{ owner.mobile }})</option>
                      </select>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col">
                      <label>Name</label>
                      <div>
                        <input type="text" required class="form-control" v-model="form.owner_name" />
                      </div>
                    </div>
                    <div class="col">
                      <label>Mobile</label>
                      <div>
                        <input type="text" required class="form-control" v-model="form.mobile" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row" v-if="form.owner">
                    <div class="col">
                      <label for>Name</label>
                      <div>{{ form.owner.name }}</div>
                    </div>
                    <div class="col">
                      <label for>Mobile</label>
                      <div>{{ form.owner.mobile }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="mt-4 alert alert-info">Please edit project to add owner</div>

              <div v-if="showSubmitBtn" class="mt-4">
                <button type="submit" class="btn btn-primary">Submit</button>
                <button
                  type="button"
                  class="btn"
                  @click="modals.addProjectModal = false; tab = 1; init()"
                >Close</button>
              </div>
            </form>
          </div>

          <!-- RETURNS -->
          <div v-if="tab == 2">
            <project-returns :project="form"></project-returns>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ProjectReturns from "./Returns";

export default {
  components: {
    flatPicker,
    ProjectReturns,
  },
  data() {
    return {
      ownerSelect: true,
      owners: [],
      projects: [],
      modals: {
        addProjectModal: false,
      },
      pListKey: 0,
      projects: [],
      isModalActive: false,
      form: {
        name: "",
        monthly_return: 0,
        start_date: "",
        end_date: "",
        invested_amount: 0,
        sales_price: 0,
        months: 0,
        locked: false,
        project_code: "",
        owner_name: "",
        mobile: "",
        owner_id_select: null,
        send_alert: false,
      },
      mode: 1,
      filter: "current",
      tab: 1,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    showSubmitBtn() {
      if (this.form.locked) {
        if (this.$store.getters["auth/user"].is_super) {
          return true;
        }
        return false;
      }
      return true;
    },
    monthlyTotal() {
      let total = 0;
      this.projects.forEach((el) => {
        total += el.monthly_return;
      });
      return total;
    },
    investTotal() {
      let total = 0;
      this.projects.forEach((el) => {
        total += el.invested_amount;
      });
      return total;
    },
    grandTotalBondInvestments() {
      let sum = 0;
      this.projects.forEach((el) => {
        el.investments.forEach((element) => {
          sum += parseInt(element.monthly_return);
        });
      });

      return sum;
    },
    grandTotalReturns() {
      let sum = 0;
      /* this.projects.forEach(el => {
        el.returns.forEach(element => {
          sum += parseInt(element.amount);
        });
      }); */

      return sum;
    },

    grandTotalRcvd() {
      let sum = 0;
      this.projects.forEach((el) => {
        sum += parseInt(el.rcvd);
      });

      return sum;
    },

    grandTotalRcvbl() {
      let sum = 0;
      this.projects.forEach((el) => {
        sum += parseInt(el.cumulative);
      });

      return sum;
    },

    grandTotalOutstanding() {
      let sum = 0;
      this.projects.forEach((el) => {
        sum += parseInt(el.rcvd) - parseInt(el.cumulative);
      });

      return sum;
    },

    totalSalePrice() {
      let sum = 0;
      this.projects.forEach((el) => {
        sum += parseInt(el.sales_price);
      });

      return sum;
    },
  },
  methods: {
    init() {
      api.bonds.getProjects(this.filter).then((res) => {
        this.projects = res.data.data;
      });
      api.bonds.getProjectOwners(this.filter).then((res) => {
        this.owners = res.data.data;
      });
    },
    getProjectInvestTotal(investments) {
      let sum = 0;
      investments.forEach((element) => {
        sum += parseInt(element.monthly_return);
        // this.grandTotalBondInvestments += sum;
      });
      return sum;
    },
    getProjectReturnsTotal(returns) {
      let sum = 0;
      returns.forEach((element) => {
        sum += parseInt(element.amount);
        // this.grandTotalBondInvestments += sum;
      });
      return sum;
    },
    submit(e) {
      e.preventDefault();
      if (this.mode == 1) {
        api.bonds.postBondProject(this.form).then((res) => {
          this.modals.addProjectModal = false;
          this.init();
          this.pListKey++;
          this.resetForm();
        });
      }

      if (this.mode == 2) {
        /* if(this.form.send_alert) {

        } */

        api.bonds.putBondProject(this.form, this.form.uuid).then((res) => {
          this.modals.addProjectModal = false;
          this.init();
          this.pListKey++;
          this.resetForm();
        });
      }
    },

    editProject(e, val) {
      e.preventDefault();
      this.modals.addProjectModal = true;
      this.mode = 2;
      this.form = val;
    },

    resetForm() {
      this.form = {
        name: "",
        monthly_return: 0,
        start_date: "",
        end_date: "",
        invested_amount: 0,
        sales_price: 0,
        months: 0,
        locked: false,
      };
    },
  },
};
</script>


<style scoped>
table {
  overflow: hidden;
}

td,
th {
  padding: 10px;
  position: relative;
  outline: 0;
}

body:not(.nohover) tbody tr:hover {
  background-color: #ffa;
}

td:hover::after,
thead th:not(:empty):hover::after,
td:focus::after,
thead th:not(:empty):focus::after {
  content: "";
  height: 10000px;
  left: 0;
  position: absolute;
  top: -5000px;
  width: 100%;
  z-index: -1;
}

td:hover::after,
th:hover::after {
  background-color: #ffa;
}

td:focus::after,
th:focus::after {
  background-color: lightblue;
}

/* Focus stuff for mobile */
td:focus::before,
tbody th:focus::before {
  background-color: lightblue;
  content: "";
  height: 100%;
  top: 0;
  left: -5000px;
  position: absolute;
  width: 10000px;
  z-index: -1;
}
</style>