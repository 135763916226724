<template>
  <div v-if="render">
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <h1 class="text-white">{{project.name}}</h1>

      <div v-if="due" class="alert alert-danger text-center">Due payment</div>

      <div class="row mb-3">
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">monthly_return</h5>
                  <span class="h2 font-weight-bold mb-0">{{ project.monthly_return | currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">sales_price</h5>
                  <span class="h2 font-weight-bold mb-0">{{ project.sales_price | currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">cost price</h5>
                  <span class="h2 font-weight-bold mb-0">{{ project.invested_amount | currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">months</h5>
                  <span class="h2 font-weight-bold mb-0">{{ project.months }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">months_to_end</h5>
                  <span class="h2 font-weight-bold mb-0">{{ project.months_to_end }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0" show-footer-line="true">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">days_to_end</h5>
                  <span class="h2 font-weight-bold mb-0">{{ project.days_to_end }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button class="btn btn-primary" @click="init()">Refresh</button>
      <button class="btn btn-primary" @click="showScheduleItemForm()">Add schedule item</button>
      <button
        class="btn btn-danger"
        @click="showUpdateBtn = !showUpdateBtn"
        v-if="$store.getters['auth/user'].is_super"
      >Update</button>
      <button
        class="btn btn-primary"
        @click="modals.paymentModal = true; resetPaymentForm(); paymentFormMode = 1"
        v-if="schedule.length"
      >Receive payment</button>
      <button class="btn btn-primary" @click="resetChanges()" v-if="upadteInitd">Undo all changes</button>
      <button class="btn btn-danger" @click="saveChanges()" v-if="upadteInitd">Save changes</button>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card">
        <div class="card-body">
          <h3>Payment Schedule</h3>

          <div v-if="schedule.length">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th v-if="showUpdateBtn" width="10%"></th>
                  <th>Period</th>
                  <th>Monthly</th>
                  <th>Cumulative</th>
                  <th>Payments</th>
                </tr>
              </thead>
              <tr v-for="(row, i) in schedule" :key="i" :class="{ 'bg-light' : row.current }">
                <td>{{ row.sequence }}</td>
                <td v-if="showUpdateBtn">
                  <div v-if="row.payments < row.monthly">
                    <span
                      class="badge badge-danger mr-1 pointer"
                      @click="updateScheduleInit(i)"
                    >Edit</span>
                    <span
                      class="badge badge-light pointer"
                      @click="deleteSchedule(i, row.uuid)"
                    >Delete</span>
                  </div>
                </td>
                <td>
                  {{ row.month }}/{{ row.year }}
                  <span
                    class="badge badge-danger float-right"
                    v-if="row.due"
                  >Due</span>
                </td>
                <td>{{ row.monthly | currency }}</td>
                <td>{{ row.cumulative | currency }}</td>
                <td>
                  {{ row.payments | currency }}
                  <span
                    v-if="row.payments"
                    class="float-right badge badge-primary pointer"
                    @click="schedulePayments = row.payment_lines; modals.paymentLines = true"
                  >Payments</span>
                </td>
              </tr>
            </table>
          </div>
          <div v-else>
            <button class="btn btn-primary" @click="createSchedule()">Create schedule</button>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="modals.paymentModal" modal-classes="modal-dialog-centered modal-lg">
      <div class="p-3">
        <h2 class="text-uppercase">Create/Update payment</h2>
        <form @submit.prevent="paymentSubmit">
          <div class="row">
            <div class="col-4 mb-3">
              <label for>Schedule</label>
              <select name class="form-control" required v-model="paymentForm.schedule_uuid">
                <option
                  :value="row.uuid"
                  v-for="row in schedule"
                  :key="row.uuid"
                >{{ row.period }} - {{ row.payments }}</option>
              </select>
            </div>
            <div class="col-4 mb-3">
              <label for>Amount</label>
              <input
                v-model="paymentForm.amount"
                type="number"
                step="any"
                required
                class="form-control"
              />
            </div>
            <div class="col-4 mb-3">
              <label for>Remarks</label>
              <input v-model="paymentForm.remarks" type="text" class="form-control" />
            </div>
            <div class="col-4 mb-3">
              <label for>Attachment</label>
              <input type="file" v-on:change="handlePaymentFileUpload()" ref="file" />
            </div>
          </div>
          <div class="mt-4">
            <button type="submit" :disabled="loading" class="btn btn-primary">Submit</button>
            <button type="button" @click="modals.paymentModal = false;" class="btn">Close</button>
          </div>
        </form>
      </div>
    </modal>

    <modal :show.sync="modals.scheduleModal" modal-classes="modal-dialog-centered modal-lg">
      <div v-if="upadteInitd" class="p-3">
        <h2 class="text-uppercase">Update schedule</h2>
        <form>
          <div class="row">
            <div class="col">
              <label for>Amount</label>
              <input
                v-model="schedule[scheduleUpdateIndex].monthly"
                type="number"
                step="any"
                required
                class="form-control"
              />
            </div>
            <div class="col">
              <label for>Remarks</label>
              <input
                v-model="schedule[scheduleUpdateIndex].remarks"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <!-- <button type="submit" :disabled="loading" class="btn btn-primary mt-4">Submit</button> -->
        </form>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="modals.scheduleModal = false;">Close</base-button>
      </template>
    </modal>

    <modal :show.sync="modals.scheduleItemModal" modal-classes="modal-dialog-centered modal-lg">
      <div class="p-3">
        <h2 class="text-uppercase">Schedule item</h2>
        <form @submit.prevent="scheduleItemSubmit">
          <div class="row">
            <!-- <div class="col-4 mb-3">
              <label for>sequence</label>
              <input
                v-model="scheduleItemForm.sequence"
                type="number"
                step="any"
                required
                class="form-control"
              />
            </div>-->
            <!-- <div class="col-4 mb-3">
              <label for>period</label>
              <input
                v-model="scheduleItemPeriod"
                readonly
                type="text"
                required
                class="form-control"
              />
            </div>-->
            <div class="col-4 mb-3">
              <label for>year</label>
              <select class="form-control" v-model="scheduleItemForm.year">
                <option v-for="i in years" :value="i" :key="i">{{ i }}</option>
              </select>
            </div>
            <div class="col-4 mb-3">
              <label for>month</label>
              <select class="form-control" v-model="scheduleItemForm.month">
                <option v-for="i in monthsCbo" :value="i" :key="i">{{ i }}</option>
              </select>
            </div>
            <div class="col-4 mb-3">
              <label for>monthly</label>
              <input
                v-model="scheduleItemForm.monthly"
                type="number"
                step="any"
                required
                class="form-control"
              />
            </div>
            <!-- <div class="col-4 mb-3">
              <label for>cumulative</label>
              <input
                v-model="scheduleItemForm.cumulative"
                type="number"
                step="any"
                required
                class="form-control"
              />
            </div>-->
            <div class="col-4 mb-3">
              <label for>due_date</label>
              <input v-model="scheduleItemForm.due_date" type="date" required class="form-control" />
            </div>
          </div>
          <div class="mt-2">
            <button class="btn btn-primary" type="submit">Submit</button>
            <button class="btn" type="button" @click="modals.scheduleItemModal = false;">Close</button>
          </div>
        </form>
      </div>
    </modal>

    <modal :show.sync="modals.paymentLines" modal-classes="modal-dialog-centered modal-lg">
      <div class="p-3">
        <h2 class="text-uppercase">Payment lines</h2>
        <table class="table table-bordered" v-if="schedulePayments.length > 0">
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Login</th>
              <th>File</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in schedulePayments" :key="row.uuid">
              <td>{{ row.created_at }}</td>
              <td>{{ row.amount }}</td>
              <td>{{ row.login }}</td>
              <td>
                <a target="_blank" v-if="row.attachment_url" :href="row.attachment_url">View</a>
              </td>
              <td>
                <span class="badge badge-primary pointer" @click="updatePaymentInit(row)">Edit</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      project: {},
      render: false,
      months: 0,
      sales_price: 0,
      schedule: [],
      scheduleTmp: [],
      strx: [],
      modals: {
        paymentModal: false,
        scheduleModal: false,
        scheduleItemModal: false,
        paymentLines: false,
      },
      paymentFormMode: 1,
      paymentForm: {},
      due: false,
      showUpdateBtn: false,
      scheduleForm: {
        uuid: "",
        year: "",
        month: "",
        monthly: "",
        due_date: "",
      },
      upadteInitd: false,
      scheduleUpdateIndex: 0,
      scheduleDeleteUuids: [],
      scheduleItemForm: {
        project_uuid: "",
        sequence: "",
        period: "",
        year: "",
        month: "",
        monthly: "",
        cumulative: "",
        due_date: "",
      },
      schedulePayments: [],
    };
  },
  computed: {
    scheduleItemPeriod() {
      return this.scheduleItemForm.year + "/" + this.scheduleItemForm.month;
    },
    years() {
      let d = new Date();
      let lst = [];
      let start = d.getFullYear();
      let max = parseInt(start) + 5;
      for (var i = start; i < max; i += 1) {
        lst.push(i);
      }
      return lst;
    },
    monthsCbo() {
      let lst = [];
      for (var i = 1; i < 13; i++) {
        lst.push(this.leftFillNum(i, 2));
      }
      return lst;
    },

    lastScheduleItem() {
      return this.schedule[this.schedule.length - 1];
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    leftFillNum(num, targetLength) {
      return num.toString().padStart(targetLength, 0);
    },
    init() {
      api.bonds.getProject(this.$route.params.uuid).then((res) => {
        this.project = res.data.data;

        // this.schedule = res.data.data.schedule;
        this.render = true;
        this.paymentForm.amount = this.project.monthly_return;
        this.months = parseInt(this.project.months);
        this.sales_price = parseInt(this.project.sales_price);
        this.getSchdule();
      });
    },

    resetPaymentForm() {
      this.paymentForm = {
        amount: "",
        schedule_uuid: "",
        project_uuid: "",
        file: null,
        remarks: "",
      };
    },

    createSchedule() {
      api.bonds.createProjectSchedule(this.$route.params.uuid).then((res) => {
        this.init();
      });
    },
    getSchdule() {
      api.bonds.getProjectSchedule(this.$route.params.uuid).then((res) => {
        this.schedule = res.data.data.schedule;
        this.due = res.data.data.due;
        this.upadteInitd = false;
      });
    },

    handlePaymentFileUpload() {
      this.paymentForm.file = this.$refs.file.files[0];
    },

    paymentSubmit(e) {
      e.preventDefault();
      let formData = new FormData();

      formData.append("amount", this.paymentForm.amount);
      formData.append("schedule_uuid", this.paymentForm.schedule_uuid);
      formData.append("file", this.paymentForm.file);
      formData.append("remarks", this.paymentForm.remarks);
      formData.append("project_uuid", this.$route.params.uuid);

      if (this.paymentFormMode == 1) {
        api.bonds.createSchedulePayment(formData).then((res) => {
          this.init();
          this.modals.paymentModal = false;
          this.resetPaymentForm();
        });
      }

      if (this.paymentFormMode == 2) {
        api.bonds
          .updateSchedulePayment(this.paymentForm.uuid, formData)
          .then((res) => {
            this.init();
            this.modals.paymentModal = false;
            this.paymentFormMode = 1;
            this.resetPaymentForm();
          });
      }
    },

    updatePaymentInit(payment) {
      this.paymentForm = payment;
      this.paymentFormMode = 2;
      this.modals.paymentLines = false;
      this.modals.paymentModal = true;
    },

    updateScheduleInit(index) {
      this.scheduleUpdateIndex = index;
      this.modals.scheduleModal = true;
      this.upadteInitd = true;
    },
    deleteSchedule(i, uuid) {
      this.schedule.splice(i, 1);
      this.scheduleDeleteUuids.push(uuid);
      this.upadteInitd = true;
      /* if (confirm("Confirm reset?")) {
        api.bonds
          .deleteProjectSchedule(this.$route.params.uuid, schUuid)
          .then(res => {
            alert("Schedule deleted");
            this.init();
          });
      } */
    },
    resetChanges() {
      // this.setScheduleTmp(this.schedule);
      this.getSchdule();
      this.upadteInitd = false;
      this.scheduleDeleteUuids = [];
    },

    saveChanges() {
      let payload = {
        remove: this.scheduleDeleteUuids,
        schedule: this.schedule,
      };
      api.bonds
        .updateProjectSchedule(this.$route.params.uuid, payload)
        .then((res) => {
          this.getSchdule();
        });
    },

    showScheduleItemForm() {
      this.modals.scheduleItemModal = true;
      this.scheduleItemForm.sequence =
        parseInt(this.lastScheduleItem.sequence) + 1;
    },

    scheduleItemSubmit(e) {
      e.preventDefault();
      this.scheduleItemForm.project_uuid = this.$route.params.uuid;

      api.bonds
        .createProjectScheduleItem(
          this.$route.params.uuid,
          this.scheduleItemForm
        )
        .then((res) => {
          this.init();
          this.modals.scheduleItemModal = false;
        });
    },
  },
};
</script>